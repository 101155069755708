(function($) {
var app = {
	windowLoaded: function () {
		
	},
	windowResized: function () {
		var _main = this;
		_main.getViewport();
	},
	windowScrolled: function () {
		var _main = this;
		pxtop = $(window).scrollTop();

		if (pxtop >= parseInt($(window).height())) {
			$('header').addClass('scrolled');
		} else {
			$('header').removeClass('scrolled');
		}
	},
	handleSliders: function() {
		var _main=this;
		_main.debug?console.log('slider init'):'';
		// if (_main.viewport == 'desktop') {
			$('.slider-hero').slick({
				arrows: false,
				dots: true,
				autoplay: true,
				pauseOnFocus: false,
				pauseOnHover: false,
				fade: true,
				// centerMode: true,
				// centerPadding: '60px',
				// slidesToShow: 1,
			});

			$('.slider-items').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				dots: true,
				arrows: false
			});
			$('.slider-partners').slick({
				slidesToShow: 5,
				slidesToScroll: 1,
				infinite: true,
				dots: false,
				autoplay: true,
				autoplaySpeed: 1000,		
				pauseOnFocus: false,		
				pauseOnHover: false,		
				responsive: [
					{
					  breakpoint: 960,
					  settings: {
						slidesToShow: 2,						
					  }
					}
				]
			});

			if (_main.viewport == 'mobile') {
				$('.icons').slick({
					arrows: false,
					autoplay: true,
					pauseOnFocus: false,
					pauseOnHover: false,
					fade: false,
					centerMode: false,
					// centerPadding: '60px',
					slidesToShow: 1,
				});
			}

			$('.slider-hero').on('beforeChange', function(event, slick, currentSlide, nextSlide){
				$('.widget-top .inner').removeClass('active');
				$('.widget-bottom .inner').removeClass('active');
				$('#current-slide-title').fadeOut(200).text();
			});
			$('.slider-hero').on('afterChange', function(event, slick, currentSlide, nextSlide){				
				var current_title = $('.slider-hero .slick-slide.slick-active .slide-title').text();
				$('.widget-top .inner.slide'+currentSlide).addClass('active');
				$('.widget-bottom .inner.slide'+currentSlide).addClass('active');
				$('#current-slide-title').fadeIn(200).text(current_title);				
			});
			$('.hero-slider-prev').on('click', function() {
				$('.slider-hero').slick('slickPrev');
			});
			$('.hero-slider-next').on('click', function() {
				$('.slider-hero').slick('slickNext');
			});
		// }
	},
	recognisePage: function() {
		var _main = this;
		var body_type = $('body').attr('data-page');
		_main.typePage = body_type;
		_main.debug?console.log('page type: ',body_type):'';
	},
	mobileSpecific: function() {
		var _main = this;
		_main.debug?console.log('run mobile specific operations'):'';
		$('.mobile-nav').detach().appendTo('body');
	},
		
	handleWaypoints: function() {

		_main.debug?console.log('waypoints init'):'';
		  
		
		// var waypoint = new Waypoint({
		// 	element: $('.scroll-trigger#nav'),
		// 	handler: function(direction) {
		// 		if (direction == "down") {
		// 			// console.log('down');
		// 			$('header').addClass('scrolled');
		// 		} else {
		// 			// console.log('up');
		// 			$('header').removeClass('scrolled');
		// 		}
		// 	}			
		// });

		$('section').each(function(){

			var self = $(this);
		
			$(this).waypoint({
				handler: function(){
					self.addClass('shown');
				},
				offset: '75%'				
			});
		})

	
	},	
	events: function () {
		var _main = this;			


		$('nav a').on('click', function(e) {			
			e.preventDefault();			
			$('.hamburger.menu').click();
			$("html, body").animate({				
				// scrollTop: $($(this).attr('href')).offset().top - parseInt(header*1.5)
				scrollTop: $($(this).attr('href')).offset().top
			}, "slow");			
		});
		$('.slider-gallery').on('click', '.slick-current img' , function() {
			_main.debug?console.log('opening image'):'';
			img_url = $(this).attr('src');
			$('.gallery-zoom').append('<img src="'+img_url+'" alt="">').addClass('show');
		});
		$('.gallery-zoom .close').on('click', function() {
			_main.debug?console.log('closing image zoom'):'';
			$('.gallery-zoom').removeClass('show');
			$('.gallery-zoom img').remove();
		});
		$('.hamburger').on('click', function() {
			$('.main-menu').toggleClass('show');
		});
		$('.hamburger').on('click', function() {
			if (_main.hasNav == true) {
				_main.hasNav = false;
				// _main.enableScroll();
			} else {
				_main.hasNav = true;
				// _main.disableScroll();
			}
			_main.debug?console.log('current nav: ',_main.hasNav):'';
			$(this).toggleClass('active');	
			$('nav').toggleClass('show');	
			
		});
		$('.scroll-top').on('click', function() {
			$("html, body").animate({				
				scrollTop: 0
			}, 400);
		});		
		$('.nav-trigger').on('click', function() {
			// $("html, body").animate({				
			// 	scrollTop: 0
			// }, 400);
			$('body').toggleClass('has-mobile-nav');
			$(this).toggleClass('active');
			$('.mobile-nav').toggleClass('active');
		});

		$('div[itemprop="mainEntity"] h2').on('click', function() {
			$(this).toggleClass('active').next('div[itemprop="acceptedAnswer"]').slideToggle();
		});

		$('.hamburger').on('click', function() {
			$(this).toggleClass('active');	
			$('.mobile-nav').toggleClass('active');	
			$('body,header').toggleClass('has-mobile-nav');
		});
	},
	getViewport: function () {
		_main = this;
		wh = $(window).width();
		if (wh <= _main.breakpointMobile) {
			_main.viewport = 'mobile';
			_main.mobileSpecific();
		} else if (wh > _main.breakpointMobile && wh <= _main.breakpointTablet) {
			_main.viewport = 'tablet';
		} else {
			_main.viewport = 'desktop';
		}
		(_main.debug) ? console.log('viewport: ', _main.viewport) : '';		
	},
	init: function () {
		_main = this;
		_main.getViewport();
		_main.events();			
		_main.handleSliders();		
		_main.recognisePage();
		_main.handleWaypoints();	
	},
	typePage: null,
	debug: true,
	viewport: null,

	breakpointMobile: 720,
	breakpointTablet: 1024
}

$(document).ready(function () {
	app.init();
});

$(window).on('load', function () {
	app.windowLoaded();
});

$(window).resize(function () {
	app.windowResized();
});

$(document).scroll(function () {
	app.windowScrolled();
});
}(jQuery));